import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { loadUser } from '../../../../redux/actions/authActions';
import reqConfig from '../../../../utils/reqConfig';

const BasicInfo = () => {
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
  }, [user]);

  const update = (e) => {
    const form = e.target;
    e.preventDefault();
    form.classList.add('was-validated');

    if (form.checkValidity() === false) return;

    const config = reqConfig(token);

    axios
      .put(`/api/auth/user/${user._id}`, { name, email, password }, config)
      .then((res) => {
        dispatch(loadUser());
        setName(res.data.data.name);
        setEmail(res.data.data.email);
        setPassword('');
        setError('');
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
        form.classList.remove('was-validated');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) {
            setError('Invalid Password');
          } else {
            setError(
              'There was an issue updating your information. Please try again later. If the issue continues please contact the Development Team.'
            );
          }
        } else {
          setError(
            'There was an issue updating your information. Please try again later. If the issue continues please contact the Development Team.'
          );
        }
      });
  };

  return (
    <div>
      <h4>Basic Information</h4>
      <div>
        {error ? <div className="alert alert-danger">{error}</div> : null}
        {success ? (
          <div className="alert alert-success">We have updated your info.</div>
        ) : null}
        <form onSubmit={update} noValidate>
          <div className="form-row  align-items-end">
            <div className="col-auto">
              <div className="form-group">
                <label htmlFor="name1">Name</label>
                <input
                  value={name}
                  className="form-control"
                  type="text"
                  id="name1"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-auto">
              <div className="form-group">
                <label htmlFor="email1">Email</label>
                <input
                  value={email}
                  className="form-control"
                  type="email"
                  id="email1"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-auto">
              <div className="form-group">
                <label htmlFor="confirmPass">Confirm Your Password</label>
                <input
                  value={password}
                  className="form-control"
                  type="password"
                  id="confirmPass"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-auto">
              <div className="form-group">
                <button className="btn btn-primary" type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BasicInfo;
