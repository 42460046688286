import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import axios from 'axios';
import MediaList from './MediaList';
import MediaUpload from './MediaUpload';
import reqConfig from '../../../../../utils/reqConfig';

const Media = ({ vehicle, getVehicle }) => {
  const token = useSelector((state) => state.auth.token);
  const [media, setMedia] = useState([]);
  const [changed, setChanged] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setMedia(JSON.parse(JSON.stringify(vehicle.media)));
  }, [vehicle]);

  function updateOrder(current, to) {
    const newArr = media;

    newArr.splice(to, 0, newArr.splice(current, 1)[0]);

    newArr.forEach((element, index) => {
      element.displayOrder = (index + 1).toString();
    });

    setMedia([...newArr]);
    setChanged(true);
  }

  function deleteObj(idx) {
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to delete this media object?')) return;
    const newArr = media;

    newArr.splice(idx, 1);

    newArr.forEach((element, index) => {
      element.displayOrder = (index + 1).toString();
    });

    setMedia([...newArr]);
    setChanged(true);
  }

  function addMedia(obj) {
    setMedia([...media, obj]);
    setChanged(true);
  }

  function save() {
    const config = reqConfig(token);
    setSaving(true);
    axios
      .post(`/api/media/save/${vehicle.stockNumber}`, { media }, config)
      .then(() => {
        getVehicle(vehicle.stockNumber);
        setChanged(false);
        setSaving(false);
        setSuccess(true);
        setError(false);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch(() => {
        setSaving(false);
        setError(true);
      });
  }

  return (
    <div>
      <Prompt
        when={changed}
        message="Are you sure you want to leave? Unsaved changes won't be saved."
      />
      <MediaUpload
        vehicle={vehicle}
        next={media.length + 1}
        addMedia={addMedia}
        changed={changed}
        save={save}
      />
      {error ? (
        <div className="alert alert-danger text-center">
          We had issues saving your changes please try again later. If the issue
          persist please contact the development team.
        </div>
      ) : null}
      {success ? (
        <div className="alert alert-success text-center">
          Your changes have saved
        </div>
      ) : null}
      {saving ? (
        <div className="alert alert-warning text-center">Saving...</div>
      ) : (
        <MediaList
          media={media}
          updateOrder={updateOrder}
          deleteObj={deleteObj}
        />
      )}
    </div>
  );
};

export default Media;
