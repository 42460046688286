import React from 'react';

const VehicleDetails = ({ vehicle }) => {
  return (
    <div>
      <h3 className="underline">
        Vehicle Details
        {vehicle ? (
          <span style={{ fontSize: '1.2rem' }}>
            <span className="mx-2 badge badge-info">
              {vehicle.active ? vehicle.status : 'NOT ACTIVE'}
            </span>
            {vehicle.status === 'AVAILABLE' &&
            vehicle.hidden === false &&
            vehicle.active === true ? (
              <i className="fas fa-eye text-dark" title="visible on website" />
            ) : (
              <i
                className="fas fa-eye-slash text-secondary"
                title="not visible on website"
              />
            )}
          </span>
        ) : null}
      </h3>
      {vehicle ? (
        <div className="row">
          <div className="col-sm-6">
            <div>Stock #: {vehicle.stockNumber}</div>
            <div>Year: {vehicle.year}</div>
            <div>Make: {vehicle.make}</div>
            <div>Model: {vehicle.model}</div>
            <div>Category: {vehicle.category}</div>
          </div>
          <div className="col-sm-6">
            <div>Capacity: {vehicle.capacityString}</div>
            <div>A/C: {vehicle.airConditioning ? 'yes' : 'no'}</div>
            <div>Mileage: {vehicle.engine.miles}</div>
            <div>Brake: {vehicle.brake}</div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-content-center">
          please select a vehicle
        </div>
      )}
    </div>
  );
};

export default VehicleDetails;
