import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import reqConfig from '../../../../../utils/reqConfig';

const MediaUpload = ({ next, addMedia, changed, save }) => {
  const token = useSelector((state) => state.auth.token);
  const [type, setType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [embedUrl, setEmbedUrl] = useState('');
  const [error, setError] = useState('');

  function onSubmit(e) {
    const form = e.target;
    e.preventDefault();
    form.classList.add('was-validated');

    if (form.checkValidity() === false) return;

    if (
      !(
        selectedFile.type === 'image/jpeg' ||
        selectedFile.type === 'image/png' ||
        selectedFile.type === 'image/gif'
      )
    ) {
      setError('File must be a image (jpg,png,gif)');
      return;
    }
    setError('');

    const fileRender = new FileReader();

    fileRender.onload = () => {
      const img = new Image();

      img.onload = () => {
        if (
          (img.width % 640 !== img.width && img.width % 640 !== 0) ||
          (img.height % 428 !== img.height && img.height % 428 !== 0)
        ) {
          setError('file should be 640 X 428 or equivilant aspect ratio');
        } else {
          setError('');

          const formData = new FormData();
          formData.append('imageFile', selectedFile);

          const config = reqConfig(token);
          config.headers['Content-Type'] = 'multipart/form-data';
          axios
            .post(`/api/media/upload/${type}`, formData, config)
            .then((res) => {
              const newMediaObj = {
                type: type === 'img' ? 'image' : 'video',
                url: type === 'img' ? res.data.data.fileName : embedUrl,
                displayOrder: next,
                thumb: type === 'thumbnail' ? res.data.data.fileName : '',
              };
              addMedia(newMediaObj);
              setType('');
              setEmbedUrl('');
            })
            .catch(() => {
              setEmbedUrl('');
              setError(
                'There was an issue uploading that image. Please try again later. If the issue continues please contact the Development Team.'
              );
            });
        }
      };

      img.src = fileRender.result;
    };
    fileRender.readAsDataURL(selectedFile);
  }

  return (
    <div className="mt-4 mb-5">
      <h3 className="mb-3 text-center">Add a...</h3>
      <div className="mb-3 text-center">
        <button
          onClick={() => setType('img')}
          type="button"
          className="btn btn-primary"
        >
          Image
        </button>
        <span className="d-inline-block mx-3 bold">Or</span>
        <button
          onClick={() => setType('thumbnail')}
          type="button"
          className="btn btn-primary"
        >
          Video
        </button>
      </div>
      {error ? <div className="alert alert-danger">{error}</div> : null}
      {type ? (
        <div>
          <form onSubmit={onSubmit} noValidate>
            <div className="form-group">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="file"
                  accept=".jpg,.png,.gif"
                  required
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                  }}
                />
                <label className="custom-file-label" htmlFor="file">
                  {type === 'img' ? 'Choose Images' : 'Choose Thumbnail'}
                </label>
              </div>
              <p>{selectedFile ? selectedFile.name : 'please select file'}</p>
            </div>
            {type === 'thumbnail' ? (
              <div className="form-group">
                <label htmlFor="embedLink">Video Embed Link</label>
                <input
                  className="form-control"
                  type="url"
                  id="embedLink"
                  value={embedUrl}
                  onChange={({ target }) => setEmbedUrl(target.value)}
                  required
                />
                <div className="invalid-feedback">
                  Please choose a valid url.
                </div>
              </div>
            ) : null}
            <button className="btn btn-primary btn-block" type="submit">
              Upload
            </button>
            <button
              className="btn btn-secondary btn-block"
              type="button"
              onClick={() => setType('')}
            >
              Cancel
            </button>
          </form>
        </div>
      ) : (
        <div>
          <button
            className="btn btn-primary btn-block"
            type="button"
            disabled={!changed}
            onClick={save}
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default MediaUpload;
