const reqConfig = (token) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  if (token) {
    config.headers['auth-token'] = token;
  }

  return config;
};

export default reqConfig;
