import React from 'react';

const GetEmail = ({ sendReset, email, setEmail }) => {
  return (
    <form onSubmit={sendReset} noValidate>
      <div className="form-group">
        <label htmlFor="email">Please Enter Your Email</label>
        <input
          type="email"
          id="email"
          className="form-control"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          required
        />
      </div>
      <button className="btn btn-primary" type="submit">
        Send Reset
      </button>
    </form>
  );
};

export default GetEmail;
