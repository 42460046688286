import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import reqConfig from '../../../../utils/reqConfig';

const UpdatePassword = () => {
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const update = (e) => {
    const form = e.target;
    e.preventDefault();
    form.classList.add('was-validated');

    if (form.checkValidity() === false) return;

    const config = reqConfig(token);
    //

    axios
      .put(
        `/api/auth/password/change/${user._id}`,
        { newPassword, oldPassword },
        config
      )
      .then(() => {
        setNewPassword('');
        setOldPassword('');
        setError('');
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
        form.classList.remove('was-validated');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) {
            setError('Invalid Password');
          } else {
            setError(
              'There was an issue updating your password. Please try again later. If the issue continues please contact the Development Team.'
            );
          }
        } else {
          setError(
            'There was an issue updating your password. Please try again later. If the issue continues please contact the Development Team.'
          );
        }
      });
  };

  return (
    <div className="mb-3">
      <h4>Update Password</h4>
      <div>
        {error ? <div className="alert alert-danger">{error}</div> : null}
        {success ? (
          <div className="alert alert-success">
            We have updated your password.
          </div>
        ) : null}
        <form onSubmit={update} noValidate>
          <div className="form-row align-items-end">
            <div className="col-auto">
              <div className="form-group">
                <label htmlFor="oldPassword">Old Password</label>
                <input
                  className="form-control"
                  type="password"
                  id="oldPassword"
                  value={oldPassword}
                  onChange={({ target }) => setOldPassword(target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-row align-items-end">
              <div className="col-auto">
                <div className="form-group">
                  <label htmlFor="new password">New Password</label>
                  <input
                    className="form-control"
                    type="password"
                    id="new password"
                    value={newPassword}
                    onChange={({ target }) => setNewPassword(target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="form-group">
                <button className="btn btn-primary" type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
