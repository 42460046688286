import axios from 'axios';
import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
} from '../types';

export const loadUser = () => (dispatch, getState) => {
  dispatch({ type: USER_LOADING });

  const { token } = getState().auth;

  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  if (token) {
    config.headers['auth-token'] = token;
  }

  axios
    .get('/api/auth/user/current', config)
    .then(({ data }) => dispatch({ type: USER_LOADED, payload: data }))
    .catch(() => {
      dispatch({ type: AUTH_ERROR });
    });
};

export const login = ({ email, password }) => (dispatch) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });

  axios
    .post('/api/auth/login', body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const logout = () => ({ type: LOGOUT_SUCCESS });
