import React from 'react';
import { NavLink } from 'react-router-dom';

const TabNav = () => {
  return (
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <NavLink exact className="nav-link" to="/">
          Media
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/marketing">
          Marketing
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/automate-raw">
          Raw Data - Auto/Mate
        </NavLink>
      </li>
    </ul>
  );
};

export default TabNav;
