import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Nav from '../../partials/Nav/Nav';
import YourInfo from './YourInfo/YourInfo';
import AddUser from './AddUser/AddUser';
import UserList from './UserList/UserList';
import reqConfig from '../../../utils/reqConfig';

const Admin = () => {
  const token = useSelector((state) => state.auth.token);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const config = reqConfig(token);
    axios.get('/api/auth/users', config).then((res) => {
      setUsers(res.data.data);
    });
  }, [token]);

  const refreshUsers = () => {
    const config = reqConfig(token);
    axios.get('/api/auth/users', config).then((res) => {
      setUsers(res.data.data);
    });
  };

  return (
    <div>
      <Nav />
      <div className="container">
        <h1 className="mb-4">Admin Dashboard</h1>
        <YourInfo />
        <AddUser refreshUsers={refreshUsers} />
        <UserList users={users} refreshUsers={refreshUsers} />
      </div>
    </div>
  );
};

export default Admin;
