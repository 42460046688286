import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import reqConfig from '../../../../../utils/reqConfig';

const Marketing = ({ vehicle, getVehicle }) => {
  const token = useSelector((state) => state.auth.token);
  const [tags, setTags] = useState('');
  const [features, setFeatures] = useState('');
  const [hidden, setHidden] = useState(false);
  const [promoted, setPromoted] = useState(false);
  const [promotext, setPromotext] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setHidden(vehicle.hidden);
    setPromoted(vehicle.promoted);
    setPromotext(vehicle.promotionText);
    setTags(vehicle.tags.join(', '));
    setFeatures(vehicle.features.join(', '));
  }, [vehicle]);

  const save = (e) => {
    e.preventDefault();
    const config = reqConfig(token);

    const payload = {
      hidden,
      promoted,
      tags,
      features,
      promotionText: promotext,
    };

    axios
      .put(`/api/inventory/marketing/${vehicle._id}`, payload, config)
      .then(() => {
        setError('');
        setSuccess(true);
        setChanged(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
        getVehicle(vehicle.stockNumber);
      })
      .catch(() => {
        setError(
          'There was an issue updating vehicle information information. Please try again later. If the issue continues please contact the Development Team.'
        );
      });
  };

  return (
    <div className="px-3 mt-3">
      <Prompt
        when={changed}
        message="Are you sure you want to leave? Unsaved changes won't be saved."
      />
      <h3 className="underline mb-3">Marketing Settings</h3>
      {error ? <div className="alert alert-danger">{error}</div> : null}
      {success ? (
        <div className="alert alert-success">Vehicle Updated</div>
      ) : null}
      <form onSubmit={save}>
        <div className="form-group">
          <label htmlFor="tags">Tags</label>
          <input
            className="form-control"
            type="text"
            id="tags"
            value={tags}
            onChange={({ target }) => {
              setTags(target.value);
              setChanged(true);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="features">Features</label>
          <input
            className="form-control"
            type="text"
            id="features"
            value={features}
            onChange={({ target }) => {
              setFeatures(target.value);
              setChanged(true);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="promoText">Promotion Text</label>
          <input
            className="form-control"
            type="text"
            id="promoText"
            value={promotext}
            onChange={({ target }) => {
              setPromotext(target.value);
              setChanged(true);
            }}
          />
        </div>
        <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            checked={promoted}
            id="promoted"
            onChange={() => {
              setPromoted(!promoted);
              setChanged(true);
            }}
          />
          <label className="form-check-label" htmlFor="promoted">
            Promoted
          </label>
        </div>
        <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            checked={hidden}
            id="hidden"
            onChange={() => {
              setHidden(!hidden);
              setChanged(true);
            }}
          />
          <label className="form-check-label" htmlFor="hidden">
            Hidden
          </label>
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default Marketing;
