import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import axios from 'axios';
import GetEmail from './GetEmail';
import Loader from '../../partials/Loader';
import GetResetInfo from './GetResetInfo';

const stages = {
  GET_EMAIL: 'GET_EMAIL',
  SENDING_RESET: 'SENDING RESET',
  GET_RESET: 'GET_RESET',
  RESETTING: 'RESETTING',
  RESET: 'RESET',
};

const PasswordReset = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const history = useHistory();
  const [stage, setStage] = useState(stages.GET_EMAIL);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history]);

  const sendReset = (e) => {
    const form = e.target;
    e.preventDefault();
    e.stopPropagation();
    form.classList.add('was-validated');

    if (form.checkValidity() === false) return;
    setStage(stages.SENDING_RESET);
    axios
      .post('/api/auth/password/send/reset', { email })
      .then(() => {
        setStage(stages.GET_RESET);
        setError('');
      })
      .catch(() => {
        setStage(stages.GET_EMAIL);
        setError(
          'There was an issue sending password reset. Please try again later. If the issue continues please contact the Development Team.'
        );
      });
  };

  const reset = (e) => {
    const form = e.target;
    e.preventDefault();
    e.stopPropagation();
    form.classList.add('was-validated');

    if (form.checkValidity() === false) return;
    setStage(stages.RESETTING);
    axios
      .post('/api/auth/password/reset', { password, token, email })
      .then(() => {
        setSuccess(true);
        setError('');
        setTimeout(() => {
          setSuccess(false);
          setStage(stages.RESET);
        }, 5000);
      })
      .catch(() => {
        setStage(stages.GET_RESET);
        setError(
          'There was an issue resetting your password. Make sure you entered in the right token. Tokens expier after 5 minutes. Please try again later. If the issue continues please contact the Development Team.'
        );
      });
  };

  const Render = () => {
    switch (stage) {
      case stages.SENDING_RESET:
      case stages.RESETTING:
        return <Loader />;
      case stages.GET_RESET:
        return (
          <GetResetInfo
            reset={reset}
            password={password}
            setPassword={setPassword}
            token={token}
            setToken={setToken}
          />
        );
      case stages.RESET:
        return <Redirect to="login" />;
      default:
        return (
          <GetEmail sendReset={sendReset} email={email} setEmail={setEmail} />
        );
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '100vh' }}
    >
      <div className="p-5" style={{ backgroundColor: '#fafafa' }}>
        <h1>Reset Your Password</h1>
        {error ? <div className="alert alert-danger">{error}</div> : null}
        {success ? (
          <div className="alert alert-success">
            Your Password Was Reset! You Will Be Redirected To Login In 5
            Seconds.
          </div>
        ) : null}
        {Render()}
      </div>
    </div>
  );
};

export default PasswordReset;
