import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import User from './User';
import reqConfig from '../../../../utils/reqConfig';

const UserList = ({ users, refreshUsers }) => {
  const token = useSelector((state) => state.auth.token);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const deleteUser = (id) => {
    const config = reqConfig(token);
    // eslint-disable-next-line
    if (!confirm('Are You sure you want to delete that user?')) return;
    axios
      .delete(`/api/auth/user/${id}`, config)
      .then(() => {
        setError('');
        setSuccess(true);
        refreshUsers();
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      })
      .catch(() => {
        setError(
          'There was an issue updating your information. Please try again later. If the issue continues please contact the Development Team.'
        );
      });
  };
  return (
    <div>
      <h2 className="underline mb-3">Current Users</h2>
      {error ? <div className="alert alert-danger">{error}</div> : null}
      {success ? (
        <div className="alert alert-success">The user has been deleted</div>
      ) : null}
      <div>
        <div className="row d-none d-md-flex">
          <div className="col-md-4 text-muted">Name</div>
          <div className="col-md-4 text-muted">Email</div>
        </div>
        {users.map((user, key) => (
          <User key={key} user={user} deleteUser={deleteUser} />
        ))}
      </div>
    </div>
  );
};

export default UserList;
