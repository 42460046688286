import React from 'react';
import BasicInfo from './BasicInfo';
import UpdatePassword from './UpdatePassword';

const YourInfo = () => {
  return (
    <div className="mb-5">
      <h2 className="underline">Your Info</h2>
      <div>
        <BasicInfo />
        <UpdatePassword />
      </div>
    </div>
  );
};

export default YourInfo;
