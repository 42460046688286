import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './nav.css';
import logo150 from '../../../images/logo-150.png';
import logo50 from '../../../images/logo-50.png';
import { logout } from '../../../redux/actions/authActions';

const Nav = () => {
  const dispatch = useDispatch();
  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-light">
      <div className="d-flex justify-content-between container">
        <Link to="/" className="navbar-brand d-none d-sm-block">
          <img src={logo150} alt="Inventory Sync Logo" />
        </Link>
        <Link to="/" className="navbar-brand d-block d-sm-none">
          <img src={logo50} alt="Inventory Sync Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbar"
        >
          <ul className="navbar-nav">
            <li className="nav-item mx-3 mb-sm-0 mb-2">
              <NavLink
                exact
                to="/"
                className="nav-link"
                isActive={(match, location) => {
                  if (location.pathname !== '/admin') {
                    return true;
                  }
                  return false;
                }}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item mx-3 mb-sm-0 mb-2">
              <NavLink to="/admin" className="nav-link">
                Admin
              </NavLink>
            </li>
            <li className="nav-item d-flex flex-column justify-content-center mx-3 mb-sm-0 mb-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => dispatch(logout())}
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
