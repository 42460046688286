import React from 'react';
import './media.css';
import MediaListItem from './MediaListItem';

const MediaList = ({ media, updateOrder, deleteObj }) => {
  return (
    <div>
      {media.map((obj, ind) => (
        <MediaListItem
          obj={obj}
          key={ind}
          updateOrder={updateOrder}
          max={media.length}
          deleteObj={deleteObj}
          index={ind}
        />
      ))}
    </div>
  );
};

export default MediaList;
