import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './login.css';
import logo260 from '../../../images/logo-260.png';
import { login } from '../../../redux/actions/authActions';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      if (location.state) {
        history.push(location.state.referrer);
      } else {
        history.push('/');
      }
    }
  }, [isAuthenticated, history, location]);

  const submit = (e) => {
    const form = e.target;
    e.preventDefault();
    e.stopPropagation();
    form.classList.add('was-validated');

    if (form.checkValidity() === false) return;

    dispatch(login({ email, password }));
  };

  return (
    <div className="d-flex justify-content-center align-items-center login-view">
      <div className="login-form-wrapper">
        <img src={logo260} alt="Inventory Sync Logo" />
        <h1 className="text-center">Welcome</h1>
        <h2 className="text-center">Please Login</h2>
        {error ? <div className="alert alert-danger">{error}</div> : null}
        <form onSubmit={submit} className="mb-2 needs-validation" noValidate>
          <div className="form-group">
            <label className="d-block">
              Email
              <input
                className="form-control"
                type="email"
                name="email"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                required
              />
              <div className="invalid-feedback">Please provide your email.</div>
            </label>
          </div>
          <div className="form-group">
            <label className="d-block">
              Password
              <input
                className="form-control"
                type="password"
                name="password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                required
              />
              <div className="invalid-feedback">Please provide password</div>
            </label>
          </div>
          <button className="btn btn-primary btn-block" type="submit">
            Login
          </button>
        </form>
        <small className="text-center d-block">
          <Link to="/password-reset">Forgot Password?</Link>
        </small>
      </div>
    </div>
  );
};

export default Login;
