import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import reqConfig from '../../../../utils/reqConfig';

const AddUser = ({ refreshUsers }) => {
  const token = useSelector((state) => state.auth.token);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const add = (e) => {
    const form = e.target;
    e.preventDefault();
    form.classList.add('was-validated');

    if (form.checkValidity() === false) return;

    const config = reqConfig(token);

    axios
      .post('/api/auth/register', { name, email, password }, config)
      .then(() => {
        setName('');
        setEmail('');
        setPassword('');
        setError('');
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
        form.classList.remove('was-validated');
        refreshUsers();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) {
            setError('Invalid Request, Make sure the user is unique.');
          } else {
            setError(
              'There was an issue adding that user. Please try again later. If the issue continues please contact the Development Team.'
            );
          }
        } else {
          setError(
            'There was an issue adding that user. Please try again later. If the issue continues please contact the Development Team.'
          );
        }
      });
  };

  return (
    <div className="mb-5">
      <h2 className="underline">Add New User</h2>
      {error ? <div className="alert alert-danger">{error}</div> : null}
      {success ? <div className="alert alert-success">User Added</div> : null}
      <form onSubmit={add} noValidate>
        <div className="form-row align-items-end">
          <div className="col-auto">
            <div className="form-group">
              <label htmlFor="name2">Name</label>
              <input
                className="form-control"
                type="text"
                id="name2"
                value={name}
                onChange={({ target }) => setName(target.value)}
                required
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="form-group">
              <label htmlFor="email2">Email</label>
              <input
                className="form-control"
                type="email"
                id="email2"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                required
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="form-group">
              <label htmlFor="password2">Password</label>
              <input
                className="form-control"
                type="Password"
                id="password2"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                required
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="form-group">
              <button className="btn btn-primary" type="submit">
                Add
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddUser;
