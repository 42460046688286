import React from 'react';
import VehicleSelector from './VehicleSelector';
import VehicleDetails from './VehicleDetails';

const VehicleControl = ({ getVehicle, vehicle, error }) => {
  return (
    <div className="row mb-4">
      <div className="col-md-6">
        <VehicleSelector getVehicle={getVehicle} error={error} />
      </div>
      <div className="col-md-6">
        <VehicleDetails vehicle={vehicle} />
      </div>
    </div>
  );
};

export default VehicleControl;
