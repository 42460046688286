import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Marketing from './Marketing/Marketing';
import Media from './Media/Media';

const HomeRouter = ({ vehicle, getVehicle }) => {
  return (
    <Switch>
      <Route
        exact
        path="/marketing"
        render={(props) => (
          <Marketing {...props} vehicle={vehicle} getVehicle={getVehicle} />
        )}
      />
      <Route
        exact
        path="/automate-raw"
        render={() => <pre>{vehicle.raw}</pre>}
      />
      <Route
        exact
        path="/"
        render={(props) => (
          <Media {...props} vehicle={vehicle} getVehicle={getVehicle} />
        )}
      />
    </Switch>
  );
};

export default HomeRouter;
