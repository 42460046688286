import React from 'react';

const GetResetInfo = ({ reset, password, setPassword, token, setToken }) => {
  return (
    <form onSubmit={reset} noValidate>
      <div className="form-group">
        <label htmlFor="token">
          Please Enter The <b>Token</b> Sent To Your Email
        </label>
        <input
          type="text"
          id="token"
          className="form-control"
          value={token}
          onChange={({ target }) => setToken(target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Please Enter Your New Password</label>
        <input
          type="Password"
          id="password"
          className="form-control"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          required
        />
      </div>
      <button className="btn btn-primary" type="submit">
        Reset
      </button>
    </form>
  );
};

export default GetResetInfo;
