import React, { useState } from 'react';

const VehicleSelector = ({ getVehicle, error }) => {
  const [stockNumber, setStockNumber] = useState('');
  const submit = (e) => {
    const form = e.target;
    e.preventDefault();
    form.classList.add('was-validated');

    if (form.checkValidity() === false) return;

    getVehicle(stockNumber);
  };
  return (
    <div>
      <h3 className="underline">Please Select a Vehicle</h3>
      {error ? <div className="alert alert-danger">{error}</div> : null}
      <form onSubmit={submit} noValidate>
        <div className="form-group row">
          <label htmlFor="stockNumber" className="col-auto col-form-label">
            Please enter a stock number:
          </label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              id="stockNumber"
              value={stockNumber}
              onChange={({ target }) => setStockNumber(target.value)}
              required
            />
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary">
              <i className="fas fa-search" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VehicleSelector;
