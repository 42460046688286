import React, { useState } from 'react';
import axios from 'axios';
import Nav from '../../partials/Nav/Nav';
import VehicleControl from './VehicleControl/VehicleControl';
import TabNav from './TabNav';
import HomeRouter from './HomeRouter/HomeRouter';

const Home = () => {
  const [vehicle, setVehicle] = useState(null);
  const [vehicleErr, setVehicleErr] = useState('');

  const getVehicle = (sn) => {
    axios
      .get(`/api/inventory/${sn}`)
      .then((res) => {
        if (!res.data) {
          setVehicle(null);
          setVehicleErr(
            'Could not find that vehicle. Make sure the Vehicle is in Auto/Mate.'
          );
          return;
        }
        setVehicle(res.data);
        setVehicleErr('');
      })
      .catch(() => {
        setVehicle(null);
        setVehicleErr(
          'There was an issue talking with the server. Please try again later. If the issue continues please contact the Development Team.'
        );
      });
  };

  return (
    <div>
      <Nav />
      <div className="container">
        <h1 className="mb-4">Vehicle Dashboard</h1>
        <VehicleControl
          getVehicle={getVehicle}
          vehicle={vehicle}
          error={vehicleErr}
        />
        <TabNav />
        {vehicle ? (
          <HomeRouter vehicle={vehicle} getVehicle={getVehicle} />
        ) : (
          <div className="d-flex justify-content-center aligin-items-center p-5">
            please select a vehicle above
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
