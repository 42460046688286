import React from 'react';

const User = ({ user, deleteUser }) => (
  <div className="row align-items-end mb-3">
    <div className="col-md-4 mb-2">{user.name}</div>
    <div className="col-md-4 mb-2">{user.email}</div>
    <div className="col-md-4 mb-2">
      <button
        onClick={() => deleteUser(user._id)}
        type="button"
        className="btn btn-primary"
      >
        Delete
      </button>
    </div>
  </div>
);

export default User;
