import React, { useEffect, useState } from 'react';

const MediaListItem = ({ obj, updateOrder, max, deleteObj, index }) => {
  const [displayOrder, setDisplayOrder] = useState('');

  useEffect(() => {
    setDisplayOrder(obj.displayOrder);
  }, [obj]);

  return (
    <div className="d-flex justify-content-between align-items-center media-list-item flex-wrap">
      <div className="media-list-item-img-container">
        {obj.type === 'image' ? (
          <img src={`/api/image/i/320/${obj.url}`} alt="" />
        ) : (
          <a
            target="popup"
            onClick={() => {
              window.open(obj.url, 'popup', 'width=640,height=360');
              return false;
            }}
            href={obj.url}
          >
            <img src={`/api/image/t/320/${obj.thumb}`} alt="" />
          </a>
        )}
        <i className="fas fa-times-circle" onClick={() => deleteObj(index)} />
      </div>
      <div className="text-center">
        <div>Display Order</div>
        <input
          type="number"
          value={displayOrder}
          onChange={({ target }) => {
            setDisplayOrder(target.value);
          }}
          onBlur={() => {
            if (displayOrder < 1) {
              setDisplayOrder('1');
            }
            if (displayOrder > max) setDisplayOrder(max);
            updateOrder(obj.displayOrder - 1, displayOrder - 1);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.target.blur();
          }}
        />
      </div>
      <div className="text-center">
        <div className="media-type-label">Media Type</div>
        <div className="media-type-type">{obj.type}</div>
      </div>
    </div>
  );
};

export default MediaListItem;
