import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Admin from './pages/Admin/Admin';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import { loadUser } from '../redux/actions/authActions';
import ProtectedRoute from './partials/ProtectedRoute';

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  });
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/password-reset" component={PasswordReset} />
          <ProtectedRoute path="/admin" component={Admin} />
          <ProtectedRoute
            exact
            path="/(marketing||automate-raw)?"
            component={Home}
          />
          <Route path="*">
            <div
              className="d-flex justify-content-center align-items-center text-center"
              style={{ height: '100vh' }}
            >
              <div>
                <h1>404</h1>
                <p className="text-muted">Page Not Found</p>
              </div>
            </div>
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
